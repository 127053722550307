:root {
/* spacing */
--spacing-50: 2px;
--spacing-100: 4px;
--spacing-200: 8px;
--spacing-250: 12px;
--spacing-300: 16px;
--spacing-350: 20px;
--spacing-400: 24px;
--spacing-500: 32px;
--spacing-550: 40px;
--spacing-600: 48px;
--spacing-700: 64px;

/* Sizes */
--sizing-50: 12px;
--sizing-100: 16px;
--sizing-200: 24px;
--sizing-300: 32px;
--sizing-350: 40px;
--sizing-400: 48px;
--sizing-500: 64px;
--sizing-550: 80px;
--sizing-600: 96px;
--sizing-700: 128px;
--sizing-800: 192px;
--sizing-900: 256px;
--sizing-1000: 320px;
--sizing-1100: 384px;
--sizing-1200: 512px;
--sizing-1300: 640px;
--sizing-1400: 768px;
--sizing-1500: 1024px;
--sizing-1600: 1080px;

/* colors  */
--color-black: #1f1f1f;
--color-card: #rgb(223, 223, 223);
--color-primary: #DEFE71;
--color-authentic: #DEFE71;
--color-inconclusive: #006EEB;
--color-likely: #25AC8C;
--color-suspected:#FFC107;
--color-counterfeit: #ED5A5A;
--color-grey-hover: #dbdbdb;
}

body {margin: 0;}

nav li { margin-inline: var(--spacing-300); }

.page {
  margin-inline: 5%;
}

.layout {display: flex; justify-content: center; max-width: 100%;}
.layout-left { background-color: none; width: var(--sizing-1300);}
.layout-right { background-color: none; flex: 1}

.img-square-big { height: var(--sizing-1300);  width: var(--sizing-1300);object-fit: cover;  }
.img-grid { display: grid; grid-template-columns: repeat(4, 1fr); gap: var(--spacing-200); margin-top: var(--spacing-200);  }
.img-grid * {min-width: var(--sizing-600);  object-fit: cover; width: 100%; height: auto;}

.img-grid-new-photos { width: 100%;}
.container-just-added-photo { display: flex; margin-bottom: var(--spacing-200); border: solid 1px black; margin-right: var(--spacing-250); padding: var(--spacing-250); border-radius: var(--spacing-200)}
.container-just-added-photo > img { width: var(--sizing-900); height: var(--sizing-900); object-fit: cover;}
.container-just-added-photo p {margin: 0;}
.container-just-added-photo button {margin-top: 10px;}
 
.spacer-hr {}
hr {
  height: 2px; /* Adjust the height as needed */
  background-color: #000; /* Set the background color of the hr */
  border: none; /* Remove the default border */
  margin: 16px 0; /* Set margin-top and margin-bottom to 16px */
}
.spacer-hor-16 { width:  var(--spacing-300); height: 100%; }
.spacer-ver-16 { width: 100%; height: var(--spacing-300); }
.spacer-hor-32 { width: var(--spacing-500); height: 100%; }
.spacer-ver-32 { width: 100%; height: var(--spacing-500); }
.spacer-hor-40 { width: var(--spacing-550); height: 100%; }
.spacer-ver-40 { width: 100%; height: var(--spacing-550); }

.center { display: flex; justify-content: center; align-items: center; height: 100%;}

.flex {display: flex; align-items: center;}
.flex-container-start { width: 100%; display: flex; justify-content: start; gap: var(--spacing-200)}
.flex-container-end { width: 100%; display: flex; justify-content: end; gap: var(--spacing-200)}
.flex-container { width: 100%; display: flex; justify-content: space-between; gap: var(--spacing-200)}
.flex-container > * { width: 100%; }
.flex-1  {flex: 1}

.grid { width: 100%; display: grid; }

.grid  *  {width: 100%; }

.g2-columns { grid-template-columns: repeat(2, 1fr);  gap: var(--spacing-200)}
.g3-columns { grid-template-columns: repeat(3, 1fr);  gap: var(--spacing-200)}
.g4-columns { grid-template-columns: repeat(4, 1fr);  gap: var(--spacing-200)}
.g5-columns { grid-template-columns: repeat(5, 1fr);  gap: var(--spacing-200)}
.g6-columns { grid-template-columns: repeat(6, 1fr);  gap: var(--spacing-200)}

.checkbox { display: flex;  align-items: center; justify-content: left; width: 100%;}
.checkbox:hover { cursor: pointer; }
.checkbox-img { margin: 0; padding: 0;  min-width: 16px;flex: 1;}
.checkbox-label { margin:  0; padding: 0; margin-left: 10px;  flex: 8;}

.card {background: var(--color-card); border-radius: var(--spacing-300); padding: var(--spacing-300);}

/* buttons  */
button { background: color; }
.btn-primary { background: var(--color-black); border: var(---color-black);}
.btn-primary:disabled { background: grey}
.btn-secondary { background: white; border: none; border: solid 1px black; color: var(--color-black);}
.btn-secondary:disabled { background: grey}
.btn-grey {color: grey; display: flex; }

.tag { background: yellow; padding-inline: var(--spacing-200); padding-block: var(--spacing-100); border-radius: var(--spacing-200); max-width: fit-content; font-size: 0.8rem;}

/* fonts */
.font-grey { color: grey; font-size: 0.9rem; }
textarea { width: 100%; }

/* images  */
.avatar { border-radius: 50%; height: var(--sizing-350); width: var(--sizing-350); }
.user-card-name-text { height: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;}
.user-card-name-text h6, p {margin: 0;}

/* table*/
.table-big { width: 100%; }
.table-thumb { height: var(--sizing-350); width: var(--sizing-350); object-fit: cover; border-radius: var(--spacing-100); margin-right: var(--spacing-200);}

tr { height: var(--sizing-500);} 

tr:hover { background-color: #eee; }


/* drop downs  */
.dropdown-menu > * { cursor: pointer; padding: var(--spacing-100); }
.dropdown-menu > *:hover { background: var(--color-grey-hover); }
.dropdown-title { border: solid 1px black;}


/** dialog */
.dialog { position: fixed; z-index: 1; background: rgba(0,0,0,0.8); top: 0; left: 0; height: 100vh; width: 100%; max-height: 100vh; max-width: 100vw; overflow-x: hidden; padding: 100px;}
.dialog > div { position: relative; background: white;  max-height: 100%; height: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center; padding: 100px; margin-inline: auto; }

/** add item */
.page-add-item-bottom { position: fixed; bottom: 0; left: 0; right: 0; display: flex; background: white; padding: 15px; }
.page-add-item-bottom button { flex: 1; margin-left: 5px; }
.drop-photo-area { height: 80px; width: 70%; padding: 8px; border: dashed black 2px; border-radius: 10px; display: flex; flex-direction: column; justify-content: center;  }
.drop-photo-area-center { display: "flex"; flex-direction: column; justify-content: center; align-items: center; text-align: center;}
.drop-photo-area img{ height: 80%; width: 80%; object-fit: cover;}
.drop-photo-area input { display: none;}

.each-image-page {  display: flex; margin: 5px; width: 100%;}
.each-image-page img { height: 250px; width: 40%; object-fit: cover; margin: 0; padding: 0;}
.each-image-page div { width: 60%;  margin: 0; padding: 0; }
.each-image-page div > * { display: flex; justify-content: start;  }
.each-image-with-button { margin: 10px; }
.each-image-with-button > button { width: 40%; }

.add-item-img { width: 25%; }

.add-item-uploader { width: 100%; height: 100%;}
.container-photo-adding-details { width: 100%; max-height: 100%;} 

/* other*/
.clicable {cursor: pointer;}
.loading-icon {
  transform: scale(200%);
  animation: loading-icon 1s forwards infinite;
}
@keyframes loading-icon {
  0%, 100% {transform: scale(200%);}  
  50% {transform: scale(150%);}  
}

.loading-table { margin-top: 100px; }

.analysis-bottom-bar {
  display: flex;
  width: 100%;
  min-height: 50px;
  position: fixed;
  z-index: 5;
  bottom: 0;
  left: 0;
  right: 0;
  background: #EBEBEB;
}

.analysis-bottom-bar-end {
  align-items: center;
  align-content: center;
}
.analysis-bottom-bar-end img {
  height: 26px;
}